<template>
  <section class="container-no-scroll">
    <main-screen/>
    <router-link class="come-back" to="/forum">
      <div></div>
      {{ $store.getters.GET_LANG ? 'Назад' : 'Go back' }}
    </router-link>

    <white-part class="white-part"/>

    <forum-program link="ссылка на опрос" :program_link="program_link" />
    <forum-news class="white-container" :tags-list="tagsList">Новости</forum-news>
    <program-forum/>

    <section class="white-container">
      <h2>Спикеры</h2>
      <p>Участие в форуме примут ведущие эксперты федерального и регионального уровней по взаимодействию некоммерческого
        сектора с бизнесом, государством, медиа, привлечению ресурсов, добровольчеству и благотворительной деятельности,
        новым трендам и технологиям НКО, гражданской активности.</p>
      <forum-speakers class="speakers_block" title="" :members="speakers"/>
      <p @click="moreSpeakers" class="blue_link" v-if="speakers.length && speakers.length !== this.membersShip.length">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.51 2.32829L16.7638 0.0138284H10.7531V6.18625L13.0076 3.87106C15.4952 6.42482 15.4952 10.5745 13.0076 13.1282C12.2498 13.908 11.3403 14.4571 10.3784 14.7466V17C11.8899 16.6531 13.3306 15.8835 14.5107 14.6724C17.8299 11.2603 17.8299 5.73962 14.51 2.32829ZM3.99295 13.1282C1.50535 10.5744 1.50535 6.42479 3.99295 3.87102C4.75084 3.09128 5.65962 2.54286 6.62223 2.25337V0C5.11068 0.34694 3.66998 1.11651 2.48991 2.32756C-0.829969 5.73892 -0.829969 11.2596 2.48991 14.6717L0.236031 16.9862H6.24679V10.8137L3.99295 13.1282Z"
            fill="#246CB7"/>
        </svg>
        Показать еще
      </p>
    </section>

    <forum-partners class="white-container" :partnersList="partners">Партнеры</forum-partners>

  </section>
</template>

<script>
import MainScreen from '@/components/activitiesSurveys/FinalForumMainScreen'
import WhitePart from '@/components/activitiesSurveys/FinalForumWhitePart'
import ForumNews from '@/components/activitiesSurveys/FinalForumNews'
import ForumProgram from '@/components/activitiesSurveys/FinalForumProgram'
import ForumSpeakers from '@/components/activitiesSurveys/FinalForumSpeakers'
import ForumPartners from '@/components/activitiesSurveys/ForumPartners'
import ProgramForum from '@/components/activitiesSurveys/ProgramForum'

export default {
  name: 'ForumFinal',
  data () {
    return {
      membersShip: [],
      speakers: [],
      countLine: 0,
      tagsList: [],
      program_link: {
        title: 'Программа мероприятия',
        url: '/',
        size: 217,
        type: 'PDF'
      },
      partners: []
    }
  },
  mounted () {
    // this.$store.dispatch('apiGetUnit', 2)
    this.$store.dispatch('getCurrentForumPublic', this.$route.params.id)

    const script = document.createElement('script')
    script.src = 'https://salebot.pro/js/salebot.js?v=1'
    script.charset = 'utf-8'
    const scriptCode = document.createElement('script')
    scriptCode.innerHTML = 'SaleBotPro.init({ onlineChatId: "2927"});'
    document.head.appendChild(script)

    script.onload = () => {
      document.head.appendChild(scriptCode)
    }
  },
  beforeDestroy () {
    const script1 = document.querySelector('script[src*="salebot"]')
    const elements = document.getElementsByTagName('script')
    const frame = document.getElementById('parent_frame')
    let script2

    for (var i = 0; i < elements.length; i++) {
      if (elements[i]?.innerHTML?.includes('SaleBotPro')) {
        script2 = elements[i]
        break
      }
    }
    if (script1) {
      script1.remove()
    }
    if (script2) {
      script2.remove()
    }
    if (frame) {
      frame.remove()
    }
  },
  computed: {
    GET_CURRENT_FORUM () {
      return this.$store.getters.GET_CURRENT_FORUM
    }
  },
  watch: {
    GET_CURRENT_FORUM () {
      const forum = this.$store.getters.GET_CURRENT_FORUM
      this.tagsList = forum.news_list
      this.program_link.url = `${this.$store.getters.getUrlApi}${forum.program_url}`
      this.program_link.size = forum.program_size
      this.program_link.type = forum.program_type ? forum.program_type.toUpperCase() : ''
      this.membersShip = forum.speakers
      this.partners.length = 0
      const partnersList = forum.partners
      partnersList.forEach(item => {
        this.partners.push({
          title: item.link, img: item.url, url: item.link, id: item.id, sort: item.sort
        })
      })
      this.partners = this.partners.sort((a, b) => a.id - b.id)
      this.moreSpeakers()
      if ([23, 58].includes(forum.id) && !this.$store.getters.GET_IS_METRIKA) {
        this.$store.commit('SET_IS_METRIKA', true)
        /* eslint no-eval: 0 */
        eval(`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
   ym(83213767, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });`)
      }
    }
  },
  methods: {
    // загрузить еще спикеров
    moreSpeakers () {
      this.speakers = this.membersShip.slice(0, ++this.countLine * 6)
    }
  },
  components: {
    MainScreen,
    ForumProgram,
    WhitePart,
    ForumNews,
    ForumPartners,
    ProgramForum,
    ForumSpeakers
  }
}
</script>

<style lang="scss" scoped>
  .come-back {
    margin-top: 2rem;
  }

  body {
    background: #101010;
  }

  .white-part {
    margin-top: -3rem;
  }

  .white-container {
    margin-top: 3.125rem;
    padding: 2.5rem 3.125rem;
  }

  ::v-deep .partners_item {
    width: 12.1875rem;
    height: 9.0625rem;
  }

  ::v-deep .member-committee__members {
    filter: grayscale(1);
    /*filter: sepia(1);*/
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
    margin-bottom: 1.375rem;
  }
  .speakers_block {
    margin-top: 2.25rem;
  }
  p {
    width: 65%;
    color: rgba(23, 23, 23, 0.8);
    font-size: 0.875rem;
    margin-bottom: .875rem;
    line-height: 157%;
  }

  .blue_link {
    text-align: center;
    color: #246CB7;
    width: 100%;
    cursor: pointer;
  }

  .banner {
    background: url('../../assets/bg/bg_my_project_banner.svg') right bottom no-repeat, #FFFFFF;
    background-size: 42rem;
    cursor: pointer;

    & .banner_title {
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 1.22;
      color: #1F3245;
      margin-bottom: 1.375rem;

      span {
        color: #246CB7;
      }
      q {
        quotes: "\00ab" "\00bb";
      }
    }

    p{
      width: 48%;
    }

  }

  @media screen and (max-width: 768px) {
    .white-part {
    }
    .card_containet {
      padding: 0 1rem;
      margin-top: 3.125rem;
      background: inherit;
      overflow: hidden;
    }
    .banner {
      background-size: 36rem;
    }
    .come-back {
      width: 100%;
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 420px) {
    .white-part {
      margin-top: -2rem;
    }
    .banner {
     background: white;
      > p {
        width: 100%;
      }
    }
    .come-back {
      width: 100%;
      padding: 0 1rem;
    }
  }
</style>
